import 'moment-timezone';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, MenuItem, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const DateItem = ({ date, title, to }) => {
  const classes = useStyles();

  return (
    <MenuItem component={Link} to={to}>
      <Box width="100%" mb={2}>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={2}>
            <Typography variant="body1">
              <ReactMoment tz="US/Mountain" format="MMM">
                {date}
              </ReactMoment>
            </Typography>
            <Typography variant="h3">
              <ReactMoment tz="US/Mountain" format="DD">
                {date}
              </ReactMoment>
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.content}>
            <Typography component="div" variant="h5">
              {title}
            </Typography>
            <Typography component="div" variant="body2">
              <ReactMoment tz="US/Mountain" format="dddd, h:mma">
                {date}
              </ReactMoment>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </MenuItem>
  );
};

DateItem.propTypes = {
  date: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default DateItem;
