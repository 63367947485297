import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import urlSlug from 'url-slug';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { getGCalId, getConferenceImage } from '../utils';
import CoverItem from './CoverItem';
import DateItem from './DateItem';

const useStyles = makeStyles((theme) => ({
  sundayButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  subscribeCTA: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  subscribeText: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(4),
    },
  },
}));

const EventsOverview = ({ local, conferences }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h4">Sunday Meeting</Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="body1">
                We meet as a church each Sunday morning at 10:30am for a time of
                singing, preaching, encouragement, and sharing, followed by a
                meal.
              </Typography>
            </Box>

            <div className={classes.sundayButtons}>
              <div>
                <Button
                  component={Link}
                  to="/live"
                  color="secondary"
                  startIcon={<PlayArrowIcon />}
                >
                  Live stream
                </Button>
              </div>

              <div>
                <Button
                  component={Link}
                  to="/events/sunday"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  Details & location
                </Button>
              </div>
            </div>
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h4">Local Meetings</Typography>
            </Box>

            <MenuList>
              {local.map((event) => (
                <DateItem
                  key={event.id}
                  date={new Date(event.start.dateTime)}
                  title={event.summary}
                  to={`/events/calendar/${getGCalId(event).slice(
                    0,
                    8
                  )}/${urlSlug(event.summary)}`}
                />
              ))}
            </MenuList>

            <Button
              component={Link}
              to="/events/calendar"
              color="secondary"
              endIcon={<ChevronRightIcon />}
            >
              See full calendar
            </Button>
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h4">Conferences</Typography>
            </Box>

            {conferences.length === 0 ? (
              <Box mb={2}>
                <Typography variant="subtitle1">
                  There are no upcoming conferences scheduled.
                </Typography>
              </Box>
            ) : (
              <MenuList>
                {conferences.map((conference) => (
                  <Box key={conference.slug} mb={2}>
                    <CoverItem
                      title={conference.title}
                      subtext={
                        <>
                          <ReactMoment format="MMMM D, YYYY">
                            {conference.startDate}
                          </ReactMoment>{' '}
                          &mdash;{' '}
                          <ReactMoment format="MMMM D, YYYY">
                            {conference.endDate}
                          </ReactMoment>
                        </>
                      }
                      image={getConferenceImage(conference, 'imageThumb')}
                      to={`/events/conferences/${conference.slug}`}
                    />
                  </Box>
                ))}
              </MenuList>
            )}

            <Button
              component={Link}
              to="/events/conferences"
              color="secondary"
              endIcon={<ChevronRightIcon />}
            >
              See all
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box mt={6}>
        <div className={classes.subscribeCTA}>
          <Box className={classes.subscribeText}>
            <Typography variant="h4" component="h4">
              Stay current with our upcoming events
            </Typography>
          </Box>

          <Box>
            <Button
              href="https://rlcf.us/signup"
              variant="contained"
              color="primary"
            >
              Subscribe by Email
            </Button>
          </Box>
        </div>
      </Box>
    </>
  );
};

EventsOverview.propTypes = {
  local: PropTypes.array.isRequired,
  conferences: PropTypes.array.isRequired,
};

export default EventsOverview;
